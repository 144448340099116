.centerizer{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    gap: 75px;
    justify-content: center;
    align-items: center;
}

.macContainer{
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: 50px;
}
.iphoneContainer{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 50px;
    align-items: center;
}
.imacContainer{
    display: grid;
    grid-template-rows: 0.5fr 0.7fr;
    place-items: center;
    column-gap: 50px;
}
.macDescription{
    color: aliceblue;
    font-size: 1.3rem;
    text-align: justify;
}

.phoneDescription{
    color: aliceblue;
    padding-top: 75px;
    font-size: 1.3rem;
    text-align: justify;
}

.imacDescription{
    color: aliceblue;
    padding-top: 60px;
    font-size: 1.25rem;
    text-align: justify;
}

.mac{
    height: 350px;
}
.imac{
    height: 550px;
}
.iphone{
    height: 450px;
    margin: 0 auto;
}

.macName, .iphoneName{
    font-size: 3rem;
    margin-bottom: 1rem;
}


/* For links in our Text */
.nameSite{
    text-decoration: none;
    color: rgb(4, 156, 4);
    cursor: pointer;
    font-size: 1.3rem;
}

.linkToSite{
    padding: 6px 15px;
    font-size: 1.4rem;
    background-color: transparent;
    display: inline-block;
    border-radius: 20px;
    color: black;
    background-color: aliceblue;
    text-decoration: none;
    transition: all 0.4s;
}
.imaclinkCenterer{
    padding: 7px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-top: 2rem;
}
.linkToSite:hover{
    background-color: rgba(0, 94, 0, 0.911);
    color: aliceblue;
}
.codeIcon{
    object-fit: cover;
    height: 45px;
}
.linkCenterer{
    margin-top: 20px;
    width: fit-content;
    display: flex;
    gap: 20px;
    align-items: center;
}
