
.bannerContainer{
    position: relative;
    display: flex;
    justify-content: center;

}
.banner{
    background-size: cover;
    width: 1200px;
    background-attachment: scroll;
    object-fit: cover;
    overflow-x: hidden;
}

.illustration{
    width: 425px;
    height: 425px;
    position: absolute;
    display: grid;
    justify-items: center;
    top: 55%;
    background-color: transparent;
    border-radius: 50%;
    border-bottom: 0.7rem rgba(22, 126, 9, 0.781) solid;
}

.pageName{
    position: absolute;
    text-align: center;
    margin-top: 200px;
    font-size: 5rem;
    color: aliceblue;
    background-color: transparent;
    text-shadow: 2px 2px 70px #000000;
}

/*small phone */
@media only screen and (max-device-width:450px){
    .banner{
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .pageName{
        margin-top: 50px;
    }
    .illustration{
        width: 300px;
        height: 300px;
        margin: 0 auto;
        top: 120px;
    }
    .pageName{
        font-size: 2.5rem;
    }
}
/* Small laptop */
@media only screen and (min-device-width: 322px) and (max-device-width:1215px){
    .pageName{
        font-size: 3rem;
    }
}
