
.sendBtn{
    background-color: rgba(240, 248, 255, 0.795);
    border-radius: 15px;
    width: fit-content;
    border: 1px solid green;
    color: black;
    font-size: 1.6rem;
    padding: 5px 20px;
    margin: 25px 10px;
    transition: all 0.3s;
}
.sendBtn:hover{
    background-color: green;
    border-radius: 2px;
}

.centerizer{
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 20px;
}
.labelEmailMe{
    font-family: 'Dosis', sans-serif;
    color: aliceblue;
    font-size: 4rem;
    text-align: center;
    margin-top: 15rem;
}
form{
    display: flex;
    flex-direction: column;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: left;
    padding-left: 100px;
}
.inputMailEach, .inputMailText, .inputMailName{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    font-size: 24px;
    padding: 5px;
    color: aliceblue;
}
.inputMailEach:focus, .inputMailName:focus{
    outline: none;
    border-bottom: 1px solid green;
}

.inputMailText:focus{
    outline: none;
    border: 1px solid green;
}
.inputMailText{
    border: 1px solid white;
    border-radius: 5px;
}
.package{
    display: flex;
    margin-bottom: 20px;
}
.package > input{
    margin: 35px 25px;
    margin-left: 0px;
}

.inputMailText{
    width: 85%;
    height: 200px;
    justify-content: start;
    text-align: start;
}

.inputMailEach, .inputMailName{
    width: 40%;
}
.mail{
    margin-left: 20px;
    width: 20%;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}