.profileStyle, .certificateStyle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


.profile-image {
  width: 275px;
  height: 275px;
  object-fit: cover;
  border-radius: 25px;
}
.certificate-image {
  width: 800px;
  height: 350px;
  object-fit: contain;
  border-radius: 30px;
}
  
.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active, .certificate-image.active {
  opacity: 1;
  transition-duration: 1.5s;
  transform: scale(1.08);
}

.right-arrow {
  position: absolute;
  top: 45%;
  right: 50px;
  font-size: 3rem;
  color: rgb(255, 255, 255);
  z-index: 10;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 50px;
  font-size: 3rem;
  color: rgb(255, 255, 255);
  z-index: 10;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
}

.no-arrow{
  display: none;
}


@media only screen and (max-width:667px){
  .certificate-image{
    width: 92.5%;
    height: 100%;
    border-radius: 10px;
  }
  .left-arrow, .right-arrow{
    display: none;
  }
}