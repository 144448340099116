.CVcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
    margin-bottom: 50px;
}
.pageCV1, .pageCV2{
    width: 40%;
    height: 40%;
}
.pageCV1{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
.toefl{
    width: 40%;
    padding-top: 20px;
    background-color: #ffff;

}

.refrenceLetter{
    width: 40%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.downloadCV, .downloadLink{
    background-color: white;
    text-decoration: none;
    border: none;
    font-size: 24px;
    border-radius: 20px;
    margin-top: 60px;
    padding: 7px;
    color: black;
    transition: all 0.5s;
}
.downloadLink{
    background-color: transparent;
}
.downloadCV:hover{
    background-color: green;
    color: white;
    transform: scale(1.1);
}

.socialMediaContainer{
    margin-bottom: 20px;
    cursor: pointer;
    display: grid;
    place-items: center;
}