@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&display=swap');

:root{
    /* width */
    --xl: 1200px;
    --s: 100%;


    /* spacing */
    --horizontalPageSpaceXL: 260px;
    --verticalEachSectionSpaceXL: 5em;
    --horizentalHeader-ParagraphSpaceXL: 0.25em;
    --horizontalEachSectionSpaceXL: 70px;

    --horizontalPageSpaceS: 175px;
    --verticalEachSectionSpaceS: 5em;
    --horizentalHeader-ParagraphSpaceS: 0.25em;
    --horizontalEachSectionSpaceS: 70px;
    
    /* font size */
    --fontsizeParagraphXL: 1.35rem;
    --fontsizeHeaderXL: 3em;
}  

.HomeContainer{
    max-width: var(--xl);
    margin: var(--horizontalPageSpaceXL) auto;
    margin-bottom: 0;
}
@media only screen and (max-width: 450px) {
    .HomeContainer{
        width: 92.5%;
        margin: var(--horizontalPageSpaceS) auto;
        margin-bottom: 50px;
    }
}


/* about me */

.aboutMeContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: aliceblue;
    font-size: var(--fontsizeParagraphXL);
    gap: var(--horizontalEachSectionSpaceXL);
}
.aboutMeTextContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: justify;
    height: 100%;
}
.aboutMeTitle{
    margin-bottom: var(--horizentalHeader-ParagraphSpaceXL);
    font-family: 'Dosis', sans-serif;
    font-size: var(--fontsizeHeaderXL);
    color: aliceblue;
}

/* Education */

.EducationContainer{
    margin-top: var(--verticalEachSectionSpaceXL);    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--horizontalEachSectionSpaceXL);
}
.EducationTextContainer{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: aliceblue;
    font-size: var(--fontsizeParagraphXL);
    text-align: justify;
}
.universityLogo{
    width: 300px;
}

.EducationTitle{
    text-align: left;
    font-family: 'Dosis', sans-serif;
    font-size: var(--fontsizeHeaderXL);
    margin-bottom: var(--horizentalHeader-ParagraphSpaceXL);
}


/* slider linkedin */

.slider{
    margin-top: 7em;
}

/* Basketball */
.basketballContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--horizontalEachSectionSpaceXL);
    margin-top: var(--verticalEachSectionSpaceXL);
}
.basketballTopic{
    font-family: 'Dosis', sans-serif;
    color: aliceblue;
    margin-bottom: var(--horizentalHeader-ParagraphSpaceXL);
    font-size: var(--fontsizeHeaderXL);
}
.basketball{
    margin-top: 4em;
    width: 20%;
}

.bassketballDescription{
    color: aliceblue;
    font-size: var(--fontsizeParagraphXL);
    text-align: justify;
}

/* guitar */
.guitarContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--horizontalEachSectionSpaceXL);
    width: 100%;
    margin-top: var(--verticalEachSectionSpaceXL);
}

.guitarTopic{
    font-family: 'Dosis', sans-serif;
    color: aliceblue;
    margin-bottom: var(--horizentalHeader-ParagraphSpaceXL);
    font-size: var(--fontsizeHeaderXL);
}
.guitar{
    width: 30%;
}

.guitarDescription{
    color: aliceblue;
    font-size: var(--fontsizeParagraphXL);
    text-align: justify;
}

/* Responsive mobile */
@media only screen and (max-device-width:667px){
    .aboutMe, .descriptionAboutMe, .descriptionAboutMe1, .leftTextContainer>p, .bassketballDescription, .guitarDescription{
        font-size: 1.2rem;
    }
/* About me */
    .aboutMeTitle{
        font-size: 2.5em;
    }
    .aboutMeContainer{
        gap: 60px;
        flex-direction: column;
        flex-flow: column-reverse;
    }
/* Education */
    .EducationContainer{
        flex-direction: column;
    }
    .universityLogo{
        width: 75%;
    }
    .EducationTitle{
        font-size: 2.5em;
    }

/* Basketball */
    .basketballContainer{
        flex-direction: column;
        flex-flow: column-reverse;
        gap: 0;
    }
    .basketball{
        width: 55%;
        margin-top: 0;
        margin-bottom: 2em;
    }

    .basketballTextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
    }

/* Guitar */

    .guitarContainer{
        flex-direction: column;
        gap: 0;
        margin-top: 0;
    }
    .guitar{
        width: 60%;
        margin: 2em;
    }

    .guitarTextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
    }
}