.text{
    color: aliceblue;
    font-size: 1.5rem;
}

.footerContainer{
    margin-top: 5em;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.phoneContainer{
    display: flex;
    margin-bottom: 10px;
}
.emailContainer{
    display: flex;
    margin-bottom: 10px;
}
.icons{
    width: 30px;
    height: 30px;
    margin-right: 15px;
}
.chat{
    width: 60px;
    height: 60px;
}

@media only screen and (max-width: 450px) {
    .text{
        font-size: 1.25rem;
    }
    .icons{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

