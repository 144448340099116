@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&700&display=swap');

.NavBig{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0; 
    left: 0; 
    z-index: 9999;
}
.navigationBar{
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    padding: 12px 0;
    max-width: 1200px;
    min-width: 1023px;
    width: 100%;
}
.NavMin{
    display: none;
    justify-content: space-between;
    align-items: center;
}
.noNavMinSlider{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100vw;
    top: 0;
    animation: closeNav 0.6s ease;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    transition: all 0.8s ease;
}

.NavMinSlider{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: black;
    top: 0;
    left: 0vw;
    width: 100vw;
    height: 100vh;
    animation: openNav 0.6s ease;
    z-index: 999;
    transition: all 0.8s ease;
}

.closeButton{
    color: white;
    z-index: 1000;
    position: absolute;
    right: 20px;
    top: 20px;
}
.navSliderLinks{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 500px;
    margin: auto 0;
}



.list{
    margin: auto 0;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: 55%;
    list-style-type: none;
}
.usual{
    text-decoration: none;
    background-color: transparent;
    color: aliceblue;
    font-size: 1.7rem;
    padding: 5px 10px;
    border-radius: 20px;
    transition: all 0.25s;
}
.usual:hover{
    background-color: rgba(2, 112, 2, 0.753);
}
.onPage{
    text-decoration: none;
    font-size: 1.7rem;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: aliceblue;
    color: rgba(2, 112, 2, 0.753);
}
li{
    background-color: transparent;
}

.menueIcon{
    padding-left: 20px;
}
.logo{
    height: 55px;
    padding-right: 20px;
}
.menueIcon, .logo{
    padding-top: 5px;
    padding-bottom: 5px;
}
@media only screen and (max-width: 450px) {
    .logo{
        height: 35px;
    }
}

.downloadResume{
    background-color: rgba(2, 112, 2, 0.753);
    border: none;
    border-radius: 20px;
    font-size: 1.5rem;
    padding: 3px 15px;
    font-family: 'Dosis', sans-serif;
    font-weight: bolder;
    transition: all 0.35s;
}

.downloadResume:hover, .downloadResume:active{
    background-color: white;
    color: rgba(2, 112, 2, 0.753);
    cursor: pointer;
}

@media screen and (min-width:768px) and (max-width:1023px){
    .NavBig{
        display: none;
    }
    .NavMin{
        display: flex;
    }
    .nameMin{
        color: aliceblue;
        font-size: 2rem;
        background-color: transparent;
        font-weight: bolder;
    }
    .logo{
        height: 35px;
    }
}
@media only screen and (max-device-width:667px){
    .NavBig{
        display: none;
    }
    .NavMin{
        display: flex;
    }
    .nameMin{
        color: aliceblue;
        font-size: 2rem;
        background-color: transparent;
        font-weight: bolder;
    }

}
