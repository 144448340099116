.row{
    width: 100%;
}
.carts{
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    justify-content: center;
    align-items: center;
}
.eachCart{
    width: 65px;
    object-fit: contain;
    max-height: 70px;
    margin: 0px 15px;
}



